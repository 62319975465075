import NightIcon from '@mui/icons-material/Brightness3';
import TimerIcon from '@mui/icons-material/Timer';
import DayIcon from '@mui/icons-material/WbSunny';
import {
  colors,
  Grow,
  ImageList,
  ImageListItem,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from 'tss-react/mui';
import ImageCard from '../../components/ImageCard';
import Label from '../../components/Label';
import Layout from '../../components/Layout';
import SeoHeader from '../../components/SeoHeader';
import { getDailies } from '../../state/fractals/dailies';
import insertAds from '../../utils/insertAds';
import { fractals as fractalsSection } from '../../utils/sections';

const layout = {
  SeoProps: {
    title: 'Fractals Overview',
    description:
      'Step up your daily GW2 fractal runs by learning effective tactics and advanced speedrun strategies.',
  },
  ContainerProps: {
    paper: false,
  },
};

const useStyles = makeStyles()((theme) => ({
  fractal: {
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      border: `2px solid ${theme.palette.text.primary}`,
      transform: `scale(0.99) !important`,
    },
  },
  dailyFractal: {
    border: `3px solid ${colors.green[500]}`,
    '&:hover': {
      border: `3px solid ${colors.green[300]}`,
      transform: `scale(0.99) !important`,
    },
  },
}));

function FractalsPage({
  data: {
    fractals: { edges },
  },
  location,
}) {
  const { classes } = useStyles();
  const [state, setState] = React.useState({ mounted: false });

  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down('md'));

  const fractalDailies = useSelector(getDailies);

  React.useEffect(() => {
    setState({ mounted: true });
  }, []);

  return (
    <Layout ContainerProps={layout.ContainerProps} location={location}>
      <SeoHeader path={location.pathname} {...layout.SeoProps} />

      <ImageList cols={small ? 1 : 2} gap={40} rowHeight="auto">
        {insertAds(
          fractalsSection.orderEdges(edges).map(
            ({
              node: {
                frontmatter: {
                  title,
                  image: { childImageSharp },
                  group,
                  difficulties,
                  cycle,
                  record,
                  api,
                },
                fields: { slug },
              },
            }) => {
              const isDaily = fractalDailies && fractalDailies.includes(api);

              return {
                content: (
                  <ImageCard
                    key={slug}
                    link={slug}
                    image={childImageSharp.gatsbyImageData}
                    title={
                      isDaily ? (
                        <>
                          {title}
                          <br />
                          <Label>
                            <StaticImage
                              src="../../assets/images/icons/dailyfractals.png"
                              alt="Daily Fractal Image"
                              width={16}
                              height={16}
                            />
                            Daily
                          </Label>
                        </>
                      ) : (
                        title
                      )
                    }
                    topLeft={difficulties.map(({ level }) => level).join(', ')}
                    topRight={group}
                    bottomLeft={
                      <>
                        {(cycle === 'Day' && <DayIcon fontSize="inherit" />) ||
                          (cycle === 'Night' && (
                            <NightIcon fontSize="inherit" />
                          ))}{' '}
                        {cycle}
                      </>
                    }
                    bottomRight={
                      (record && (
                        <>
                          <TimerIcon fontSize="inherit" /> {record.time}
                        </>
                      )) ||
                      'No record'
                    }
                    {...(isDaily
                      ? {
                          className: classes.dailyFractal,
                        }
                      : {
                          className: classes.fractal,
                        })}
                  />
                ),
              };
            },
          ),
          {
            style: {
              position: 'absolute',
              left: 0,
              top: 0,
              botom: 0,
              right: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            keyPrefix: `fractals-`,
            format: 'auto',
            responsive: 'true',
            slot: '7724795900',
            wrap: (inner) => (
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  paddingTop: '56.25%',
                }}
              >
                {inner}
              </div>
            ),
          },
        ).map(({ content, isAd }, index) => (
          <Grow
            className={isAd ? 'adsbygoogle' : ''}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            in={state.mounted}
            {...(state.mounted ? { timeout: (index + 1) * 100 } : {})}
          >
            <ImageListItem>{content}</ImageListItem>
          </Grow>
        ))}
      </ImageList>
    </Layout>
  );
}

export default FractalsPage;

export const query = graphql`
  {
    fractals: allMdx(
      filter: {
        fields: { section: { eq: "fractals" } }
        frontmatter: { hidden: { ne: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            api
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
            group
            difficulties {
              level
            }
            cycle
            record {
              time
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
